<div class="modal " [ngClass]="['modal', data.showClass]">
  <!-- Modal content -->
  <div class="modal-content">
    <!-- <button class="close" (click)="data.showClass = HIDE_MODAL">&times;</button> -->
    <ng-container *ngIf="!showModalReview">
      <div>
        <div class="popin-content">
          <div class="popin-title">
            <h2>Déconnexion </h2>
            <p>Etes vous sure de vouloir vous deconnecter?</p>
          </div>
          <div class="popin-footer row-direction">
            <button
              type="button"
              class="inverse"
              (click)="showModalReview =true"
            >
              <span>Oui</span>
            </button>
            <button
              type="button"
              (click)="data.showClass = HIDE_MODAL"
            >
              <span>Non</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showModalReview">
        <div>
          <div class="popin-content">
            <div class="popin-title">
              <h2>Votre avis nous interesse</h2>
              <p>Que pensez-vous de votre nouveau portail?</p>
            </div>
            <div class="review-action-container">
              <button class="review-item" type="button" id="1" (click)="submitReview('BAD'); BAD=!BAD; NEUTRAL=false; EXCELLENT=false" [ngClass]="BAD ? 'active' : ''">
                <img src="/assets/images/reviews/emoji-sad@2x.png" width="40" alt="" />
              </button>
              <button class="review-item" type="button" id="2" (click)="submitReview('NEUTRAL'); NEUTRAL=!NEUTRAL; EXCELLENT=false; BAD=false" [ngClass]="NEUTRAL ? 'active' : ''">
                <img src="/assets/images/reviews/emoji-indiferent@2x.png" width="40" alt="" />
              </button>
              <button class="review-item" type="button" id="3" (click)="submitReview('EXCELLENT'); EXCELLENT=!EXCELLENT; BAD=false; NEUTRAL=false" [ngClass]="EXCELLENT ? 'active' : ''">
                <img src="/assets/images/reviews/emoji-happy@2x.png" width="40" alt="" />
              </button>
            </div>
            <div class="review-message-container">
              <textarea type="text" placeholder="Votre commentaire..."  class="commentField" id="comment" name="comment"  [(ngModel)]="comment"
              maxlength="250"></textarea>
            </div>
            <div class="popin-footer">
              <button
              class="inverse"
                type="button"
                (click)="confirmSelection()"
              >
                <span>Valider</span>
              </button>
              <button
                type="button"
                class="inverse"
                (click)="data.showClass = HIDE_MODAL; logout()"
              >
                <span>Plus tard</span>
              </button>
            </div>
          </div>

        </div>
    </ng-container>
  </div>
</div>

