<div class="app-app-iframe">
  <iframe
    #iframe
    frameborder="0"
    style="
      overflow: hidden;
      overflow-y: auto;
      height: calc(100vh - 80px);
      width: 100%;
    "
    height="100%"
    width="100%"
  ></iframe>
</div>

