import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Advisor } from '../models/advisor';
import { EmailExpert } from '../models/emailExpert';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AdvisorService {
  public menuStatus: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  public findAdvisorByClient(client: string): Observable<Advisor> {
    return this.http.get<Advisor>(
      `${environment.apiUrl}/advisor?client=${client}`,
      httpOptions
    );
  }
  public sendMailToAdviser(
    customerNumber: string,
    emailAdvisor: EmailExpert
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/advisor/send-mail-advisor?customerNumber=${customerNumber}`,
      emailAdvisor,
      httpOptions
    );
  }
}
