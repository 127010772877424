import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'portail-customer-ui';
  apps = [];
  public userApps: string[];
  public showNewsDetails = false;

  constructor() { }
  public ngOnInit(): void { }

  public showDetails(): void {
    this.showNewsDetails = true;
  }

  public cancel(): void {
    this.showNewsDetails = false;
  }

}
