import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class BusinessConfigsService {
  constructor(private http: HttpClient) {}

  public findBusinessConfig(key: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiUrl}/business-Configs/${key}`,
      httpOptions
    );
  }
}
