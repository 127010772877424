import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { UserService } from 'src/app/service/user.service';
import { Review } from 'src/app/models/review';


@Component({
  selector: 'app-logout-popin',
  templateUrl: './logout-popin.component.html',
  styleUrls: ['./logout-popin.component.scss']
})
export class LogoutPopinComponent implements OnInit {
  review: Review = new Review();
  public comment: string;
  selectedId: string;
  modeleName: string;
  LIST = 'list';
  NEW = 'new';
  HIDE_MODAL = 'hideModal';
  SHOW_MODAL = 'showModal';
  selectedModele: LogoutPopinComponent;
  showModalReview= false
  constructor(private userService: UserService,
    private keycloakService: KeycloakService,
    ) {}
  @Input() data: { showClass: string; type: null };
  @Output() selectReview = new EventEmitter<string>();
  @Output() closeModele = new EventEmitter<string>();
    
  ngOnInit() {
  }

  confirmSelection() {
    this.review.comment = this.comment;
      this.userService.saveReview(this.review).subscribe((r) => {
       this.logout();
      });
  }

  logout() {
    localStorage.clear();
    this.keycloakService.logout();
  }

  onCloseModele() {
    return this.closeModele.emit(this.modeleName);
  }


 submitReview (id: string) {
    this.review.review = id;
 }


 
}

