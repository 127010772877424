import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TransactionalAccountRequestDto } from '../models/TransactionalAccountRquestDto';
import { TransactionalAccountResponseDto } from '../models/TransactionalAccountResponseDto';
import { AccountBalanceDto } from '../modules/dashboard/models/AccountBalanceDto';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) {}

  public getAccount(
    transactionalAccountRequestDto: TransactionalAccountRequestDto
  ): Observable<Array<TransactionalAccountResponseDto>> {
    return this.http.post<Array<TransactionalAccountResponseDto>>(
      `${environment.apiUrl}/accounts`,
      transactionalAccountRequestDto,
      httpOptions
    );
  }

  public getAccountsBalances(
    customerNumber: string
  ): Observable<Array<AccountBalanceDto>> {
    return this.http.get<Array<AccountBalanceDto>>(
      `${environment.apiUrl}/accounts/balances/clients/` + customerNumber,
      httpOptions
    );
  }
  public getAccountByClientAndTypeForCard(
    customerNumber: string,
    entite: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiUrl}/accounts/clients/cards/` +
        customerNumber +
        '?entite=' +
        entite,
      httpOptions
    );
  }
}
